import proj4 from "proj4";
import { config } from "@/config";
import moment from "moment";

export const removefirstAndLastTwoCharachters = (str: string) => {
  const firstTwoChars = str.substring(0, 2);
  const lastTwoChars = str.substring(str.length - 2, str.length);
  return str.replace(firstTwoChars, "").replace(lastTwoChars, "");
};

export const detectSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf("safari") != -1) {
    if (ua.indexOf("chrome") > -1) {
      return false;
    } else {
      return true;
    }
  }
};

export const getValFromArray = (arr: any[], name: string) => {
  return arr.find((item) => item.name === name)?.value;
};

export const getArrayFromArray = (arr: any[], name: string) => {
  return arr.find((item) => item.name === name)?.array;
};

// calculate time difference between two dates
export const timeDifference = (time1: string, time2: string) => {
  const date1 = new Date(`2022-04-03 ${time1}`);
  const date2 = new Date(`2022-04-03 ${time2}`);
  const difference = date1.getTime() - date2.getTime();
  const resultInMinutes = difference / (1000 * 60 * 60);
  return resultInMinutes;
};

// @ts-ignore
const sourceProj = new proj4.Proj("WGS84");

export const calcUTM = ({
  lat,
  lon,
}: {
  lat: number | string;
  lon: number | string;
}) => {
  proj4.defs(
    "EPSG:32637",
    "+proj=utm  +zone=37 +datum=WGS84 +units=m +no_defs +type=crs"
  ); // https://epsg.io/32632
  // @ts-ignore
  const destProj = new proj4.Proj("EPSG:32637");

  // @ts-ignore
  const p = new proj4.Point(Number(lon), Number(lat));
  const r = proj4.transform(sourceProj, destProj, p);

  return {
    x: r.x.toFixed(3),
    y: r.y.toFixed(3),
  };
};

export const calcGK = ({
  lat,
  lon,
}: {
  lat: number | string;
  lon: number | string;
}) => {
  proj4.defs(
    "EPSG:20004",
    "+proj=tmerc +lat_0=0 +lon_0=21 +k=1 +x_0=4500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,-0.13,-0.22 +units=m +no_defs +type=crs"
  ); // https://epsg.io/20004
  // @ts-ignore
  const destProj = new proj4.Proj("EPSG:20004");

  // @ts-ignore
  const p = new proj4.Point(Number(lon), Number(lat));
  const r = proj4.transform(sourceProj, destProj, p);

  return {
    x: r.x.toFixed(3),
    y: r.y.toFixed(3),
  };
};

export const calcMGRS = ({
  lat,
  lon,
}: {
  lat: number | string;
  lon: number | string;
}) => {
  proj4.defs(
    "EPSG:20004",
    "+proj=tmerc +lat_0=0 +lon_0=21 +k=1 +x_0=4500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,-0.13,-0.22 +units=m +no_defs +type=crs"
  ); // https://epsg.io/20004
  // @ts-ignore
  const p = new proj4.Point(Number(lon), Number(lat));
  const mgrs = p.toMGRS();

  return mgrs;
};

export const utmToLatLon = ({
  x,
  y,
}: {
  x: number | string;
  y: number | string;
}) => {
  proj4.defs(
    "EPSG:32637",
    "+proj=utm  +zone=37 +datum=WGS84 +units=m +no_defs +type=crs"
  ); // https://epsg.io/32632

  // @ts-ignore
  const destProj = new proj4.Proj("WGS84");
  // @ts-ignore
  const sourceProj = new proj4.Proj("EPSG:32637");

  // @ts-ignore
  const p = new proj4.Point(Number(x), Number(y));

  const r = proj4.transform(sourceProj, destProj, p);

  return {
    lat: r.y.toFixed(7),
    lon: r.x.toFixed(7),
  };
};

export const mgrsToLatLon = ({ mgrs }: { mgrs: string }) => {
  // @ts-ignore
  const p = new proj4.Point.fromMGRS(mgrs);
  return {
    lat: p.y.toFixed(7),
    lon: p.x.toFixed(7),
  };
};

export const gkToLatLon = ({
  x,
  y,
}: {
  x: number | string;
  y: number | string;
}) => {
  proj4.defs(
    "EPSG:20004",
    "+proj=tmerc +lat_0=0 +lon_0=21 +k=1 +x_0=4500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,-0.13,-0.22 +units=m +no_defs +type=crs"
  );

  // @ts-ignore
  const destProj = new proj4.Proj("WGS84");
  // @ts-ignore
  const sourceProj = new proj4.Proj("EPSG:20004");

  // @ts-ignore
  const p = new proj4.Point(Number(x), Number(y));

  const r = proj4.transform(sourceProj, destProj, p);

  return {
    lat: r.y.toFixed(3),
    lon: r.x.toFixed(3),
  };
};

const extractTextBetween = (text: string, start: string, end: string) => {
  const regex = new RegExp(`${start}(.*?)${end}`, "g");
  const matches = text.match(regex);
  return matches;
};

export const replaceVariables = ({
  str,
  withQuate = false,
  object,
}: {
  str: string;
  withQuate?: boolean;
  object: { [key: string]: any };
}) => {
  if (!str || !object) return "";
  let newStr = (str + "").replaceAll(config.specialChar, "'") + "";
  const matches = extractTextBetween(newStr, "'", "'");

  matches?.map((key) => {
    const itemKey = key.replaceAll("'", "");
    const value = object[itemKey] ? object[itemKey] : "";
    newStr = newStr.replaceAll(
      key,
      withQuate ? `${value ? `"${value}"` : null}` : `${value ? value : null}`
    );
  });

  return newStr ? newStr : "";
};

// get miniutes difference between two dates
export const getMinutesDiff = (date1: Date, date2: Date) => {
  const diff = (date2.getTime() - date1.getTime()) / 1000;
  return Math.abs(Math.round(diff / 60));
};

export function getCSSVariableValue(variableName: string) {
  let hex = getComputedStyle(document.documentElement).getPropertyValue(
    variableName
  );
  if (hex && hex.length > 0) {
    hex = hex.trim();
  }

  return hex;
}

// replace json string double quotes with backslash double quotes
export const replaceJsonDoubleQuotes = (str: string) => {
  return str.replaceAll('"', '\\"');
};

// convert lat lng to DMS
export const convertLatLngToDMS = (lat: number, lng: number) => {
  const latResult = getDms(lat) + (lat >= 0 ? "N" : "S");
  const lngResult = getDms(lng) + (lng >= 0 ? "E" : "W");
  return {
    lat: latResult,
    lng: lngResult,
  };
};

export const getDms = (val: number) => {
  const valDeg = Math.abs(Math.trunc(val));
  const valMin = Math.abs(Math.trunc((val - valDeg) * 60));
  const valSec = Math.abs(((val - valDeg) * 3600) % 60);
  return `${valDeg}°${valMin}'${valSec.toFixed(2)}"`;
};

// convert lat, lng to degrees minutes
export const convertLatLngToDM = (lat: number, lng: number) => {
  const latResult = getDm(lat) + (lat >= 0 ? "N" : "S");
  const lngResult = getDm(lng) + (lng >= 0 ? "E" : "W");
  return {
    lat: latResult,
    lng: lngResult,
  };
};

export const getLatDegree = (val: number) => {
  return val >= 0 ? "N" : "S";
};

export const getLngDegree = (val: number) => {
  return val >= 0 ? "E" : "W";
};

export const getDm = (val: number) => {
  const valDeg = Math.abs(Math.trunc(val));
  const valMin = Math.abs(((val - valDeg) * 60) % 60);
  return `${valDeg}°${valMin.toFixed(2)}'`;
};

export const getDMSObj = (val: number) => {
  const valDeg = Math.abs(Math.trunc(val));
  const valMin = Math.abs(Math.trunc((val - valDeg) * 60));
  const valSec = Math.abs(((val - valDeg) * 3600) % 60);
  return {
    deg: valDeg.toString(),
    min: valMin.toString(),
    sec: valSec.toFixed(3).toString(),
  };
};

export const convertLatToDMSObj = (lat: number, lng: number) => {
  const latResult = getDMSObj(lat);
  const lngResult = getDMSObj(lng);
  return {
    lat: latResult,
    lng: lngResult,
  };
};

export const getDMObj = (val: number) => {
  const valDeg = Math.abs(Math.trunc(val));
  const valMin = Math.abs(((val - valDeg) * 60) % 60);
  return {
    deg: valDeg.toString(),
    min: valMin.toFixed(6).toString(),
  };
};

export const convertLatToDMObj = (lat: number, lng: number) => {
  const latResult = getDMObj(lat);
  const lngResult = getDMObj(lng);
  return {
    lat: latResult,
    lng: lngResult,
  };
};

export const convertFromDMSToLatLng = (lat: string, lng: string) => {
  const latArr = lat.split(/[^\d\w]+/);
  const lngArr = lng.split(/[^\d\w]+/);

  const latDeg = Number(latArr[0]);
  const latMin = Number(latArr[1]);
  const latSec = Number(latArr[2]);

  const lngDeg = Number(lngArr[0]);
  const lngMin = Number(lngArr[1]);
  const lngSec = Number(lngArr[2]);

  const latResult = latDeg + latMin / 60 + latSec / (60 * 60);
  const lngResult = lngDeg + lngMin / 60 + lngSec / (60 * 60);

  return {
    lat: latResult,
    lng: lngResult,
  };
};

export const convertFromDMToLatLng = (lat: string, lng: string) => {
  const latArr = lat.split(/[^\d\w]+/);
  const lngArr = lng.split(/[^\d\w]+/);

  const latDeg = Number(latArr[0]);
  const latMin = Number(latArr[1]);

  const lngDeg = Number(lngArr[0]);
  const lngMin = Number(lngArr[1]);

  const latResult = latDeg + latMin / 60;
  const lngResult = lngDeg + lngMin / 60;

  return {
    lat: latResult,
    lng: lngResult,
  };
};

export const convertDMSToDD = (
  degrees: string,
  minutes: string,
  seconds = "0",
  direction: string
) => {
  var dd = Number(degrees) + Number(minutes) / 60 + Number(seconds) / (60 * 60);

  if (direction == "S" || direction == "W") {
    dd = dd * -1;
  } // Don't do anything for N or E
  return dd;
};

export const calcSecondsBetweenTwoTimes = (time1: string, time2: string) => {
  return moment(time1, "hh:mm:ss").unix() - moment(time2, "hh:mm:ss").unix();
};

export const makeParams = (params: { [key: string]: string | number }[]) => {
  const returnVals: { [key: string]: string | number } = {};
  params.map((param) => {
    returnVals[param.name] = param.value;
  });
  return returnVals;
};

export const getWeekNumber = (date: Date) => {
  const d = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  return Math.ceil(((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
};
